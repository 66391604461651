.sayt-box .sayt-result a, .sayt-box .sayt-result .no-link, .sayt-box .sayt-heading {
  padding: 10px;
  display: block;
  position: relative; }

.sayt-box {
  padding: 0;
  margin: -1px 0 0;
  border: 1px solid #aaa;
  border-top: 0;
  list-style: none;
  text-align: left;
  box-shadow: 0 5px 10px #999;
  z-index: 9999;
  font-size: 14px;
  background-color: #fff;
  float: none; }
  .sayt-box:focus {
    outline: none;
    outline-color: transparent;
    outline-style: none;
    box-shadow: none; }
  .sayt-box:hover {
    display: block !important; }
  .sayt-box .sayt-result {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #bdc3c7;
    float: none;
    line-height: initial; }
    .sayt-box .sayt-result.hover {
      background-color: #ecf0f1 !important; }
    .sayt-box .sayt-result.selected {
      background-color: #ecf0f1 !important; }
    .sayt-box .sayt-result a {
      color: #2c3e50; }
    .sayt-box .sayt-result tr {
      vertical-align: top; }
    .sayt-box .sayt-result td {
      padding: 0; }
    .sayt-box .sayt-result p.data {
      margin: 0 !important;
      padding: 0 !important;
      line-height: initial;
      font-weight: 400;
      font-size: 14px;
      text-transform: none; }
      .sayt-box .sayt-result p.data .title {
        font-style: italic;
        color: #3498db; }
    .sayt-box .sayt-result img.preview {
      width: 60px;
      max-height: 60px; }
  .sayt-box .sayt-heading {
    background-color: #03A9F4;
    color: #B3E5FC;
    font-weight: bold;
    float: none;
    line-height: initial; }

@media (min-width: 768px) {
  .sayt-box .sayt-result a {
    padding: 15px 10px; }
  .sayt-box .sayt-result .no-link {
    padding: 15px 10px; }
  .sayt-box .sayt-heading {
    padding: 15px 10px; } }

/*# sourceMappingURL=sayt.css.map */
